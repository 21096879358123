import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";
import FormField from '../components/utilities/FormField';

import './unsubscribe.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} unsubscribe-wrapper`}>
            <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
            <div className="container unsubscribe-form">
                <div className="row">
                    <div className="col text-center justify-content-center">
                        <h1>Unsubscribe</h1>
                        <p>from Merit Mile marketing communications.</p>
                        <form action="http://www.meritmilesignal.com/t/r/u/ohhojj/" method="post">
                            <div className="form-group">
                                <label className="hiddenmd" for="email">Email address:</label>
                                <FormField fieldID={"email"} type={"email"}  name={"cm-ohhojj-ohhojj"} label={"Email"} required={true}/>
                            </div>
                            <div className="form-group">
                                <input type="submit" value="Unsubscribe" className="btn btn-primary" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)